@import '../../styles/propertySets.css';

.root {
    /* Dimensions */
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;

    @media (--viewportMedium) {
        padding-top: 2px;
    }
}

.error {
    color: var(--failColor);
}

.hide {
    display: none !important;
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;

    @media (--viewportLarge) {
        display: inline-block;
        width: 241px;
        margin-top: 100px;
    }
}

.returnButton {
    margin-top: auto;
    margin-bottom: 24px;

    @media (--viewportLarge) {
        display: block;
        width: 241px;
        margin-top: 100px;
    }
}

/* SUB-FIELDS */
.formMargins {
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.title,
.typeOfCharge {
    composes: formMargins;
}

.cancellationPoilicyTitle {
    composes: formMargins;
}

.priceInput {
    composes: formMargins;
    margin-bottom: 8px;

    flex-shrink: 0;
}

.earningsContainer {
    composes: formMargins;

}

.earnings {
    margin: 0px;
    font-size: 12px;
}

.subFieldsBtnContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.subFieldsSubmitBtn {
    width: fit-content;
    min-width: 241px;
    padding: 0px 20px;
    margin-right: 40px;
}

.subFieldsCancelBtn {
    text-decoration: none !important;

    width: fit-content;
    margin-bottom: 38px;

    color: var(--matterColor);
    background: transparent;
    font-weight: var(--fontWeightRegular);

    @media (--viewportLarge) {
        margin-bottom: 14px;
    }

    &:hover {
        background: transparent;
        border-bottom: 2px solid black;
        box-shadow: none;
    }
}

/* DEFAULT ADD-ON */
.defaultAddOnsContainer {
    display: flex;
    flex-wrap: wrap;

    margin-top: 20px;
    margin-bottom: 40px;
}

.defaultAddOnBtn {
    text-decoration: none !important;

    width: fit-content;
    font-size: 14px;

    border: 1px solid #dbdbdb;
    border-radius: 4px;

    margin-right: 10px;
    margin-bottom: 10px;
    padding: 4px 10px;

    color: var(--grey3);
    font-weight: 500;

    &:hover,
    &.active {
        color: white !important;

        background-color: var(--marketplaceSecondaryColor);
        border: 1px solid var(--marketplaceSecondaryColor);
    }
}

.defaultAddOnBtn:hover,
.defaultAddOnBtnActive {
    color: white !important;

    background-color: var(--marketplaceSecondaryColor);
    border: 1px solid var(--marketplaceSecondaryColor);
}

.defaultAddOnBtnDisabled,
.defaultAddOnBtnDisabled:hover {
    opacity: 0.5;
    color: var(--grey3) !important;

    cursor: not-allowed;
    border: 1px solid #dbdbdb;

    background-color: transparent;
}

/* ADD-ON HEADING CONTAINER */
.addOnHeadingContainer {
    display: flex;
    justify-content: space-between;
}

.customAddonLabel {
    font-size: 14px;
    font-weight: var(--fontWeightMedium);

    color: var(--marketplaceSecondaryColor);
}

.addOnActionsContainer {
    display: flex;
}

.addOnActionTextSeparator {
    margin: auto 6px;
}

.actionTextDelete>label>span,
.actionText>label>span {
    font-size: 14px;
}

.actionTextDelete:hover>label>span,
.actionText:hover>label>span {
    text-decoration: underline;
}

.actionTextDelete>label>span {
    color: var(--failColorLight) !important;
}

.actionTextDelete:hover>label>span {
    color: var(--failColor) !important;
}

/* CUSTOM ADD-ON BTN */
.customAddonBtn {
    text-decoration: none !important;
    color: var(--grey3);
    font-weight: 500;

    background: #dbdbdb;
    border-radius: 4px;

    border: 1px solid var(--matterColorAnti);
    color: var(--grey3);

    width: fit-content;
    padding: 6.5px 12.5px;
    margin: 76.5px 0px;

    &:hover {
        color: white !important;
        text-decoration: none !important;

        background: var(--matterColorAnti);
    }
}

.activityTabNavContainer {
    display: flex;
    margin-bottom: 20px;
}

.activityTab {
    margin-left: 16px;
    white-space: nowrap;

    &:first-child {
        margin-left: 0;
    }

    @media (--viewportLarge) {
        margin-left: 24px;
    }
}

.activityTabLink {
    display: inline-block;
    white-space: nowrap;

    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 24px;

    /* Font */
    @apply --marketplaceTabNavFontStyles;

    color: var(--matterColor);
    padding-bottom: 10px;
    padding-top: 10px;

    /* push tabs against bottom of tab bar */
    margin-top: auto;
    margin-right: 20px;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    transition: var(--transitionStyleButton);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
    background-position: calc(var(--TabNav_linkWidth) + 4px) center;
    /* SelectedLink's width (200px) + border thickness (4px) = 204px */

    &:hover {
        text-decoration: none;
        color: var(--matterColorDark);
    }
}

.selectedLink {
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);
}

.desktop6Cols {
    display: flex;
    flex-direction: column;

    @media (--viewportLarge) {
        justify-content: space-between;
        flex-direction: row;

        &>div {
            flex: 0.49;
        }
    }
}

.addOnCard {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.cancelPolicyInfoText,
.editInfoText {
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 24px;

    color: var(--grey1);
}

.addOnSubText {
    margin-bottom: 5px;
}

.editInfoText {
    margin-top: 0px;
    margin-bottom: 20px;
}

.requiredRedStar {
    margin-right: 5px;
    color: var(--failColor);
}

.activityDetailsNextBtn {
    margin-top: 30px;
}

.activityDetailsSectionContainer.show,
.activityAddOnsSectionContainer.show {
    display: block;
}

.hideTab {
    display: none;
}

.showTab {
    display: block;
}

.activityDetailsSectionContainer,
.activityAddOnsSectionContainer {
    display: none;
}

.clusterAddOnLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: var(--gray3);
    text-decoration-line: underline;

    margin-right: 20px;

    &:hover {
        opacity: 0.7;
    }
}

.clusterFormSectionPreviewText {
    margin-top: -18px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.cancellationTag {
    margin-top: 15px;
    background-color: black;
}

.cancellationWrapper {
    position: relative;
}

.cancellationWrapper:hover .cancellationToolTip {
    visibility: visible;
}


.cancellationToolTip {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 200px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    bottom: -155px;
    right: -15px;
    text-align: center;
}

.cancellationToolTip:before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #192733;
    padding: 5px;
    z-index: 1;
    top: -5px;
    right: 18px
}