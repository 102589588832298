@import '../../styles/propertySets.css';

.root {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
    margin-bottom: 180px;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
}

.avatar {
    /* Layout */
    flex-shrink: 0;
    margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
    /* Font */
    @apply --marketplaceH1FontStyles;
    margin-bottom: 1px;
    margin-top: 16px;
}

.logoutButton {
    @apply --marketplaceLinkStyles;
    /* Logout font is smaller and gray since the action is not recommended. */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    /* Position component */
    width: initial;
    margin: 4px 0 6px 0;
    padding: 0;
}

.inbox {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--marketplaceColor);
    position: relative;

    margin-top: auto;
    margin-bottom: 13px;
}

.navigationLink {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--marketplaceColor);

    /* Layout */
    margin-top: 0;
    margin-bottom: 11px;
}
.currentPageLink {
    color: var(--marketplaceColorDark);
}

.notificationBadge {
    position: absolute;
    top: 4px;
    right: -26px;

    /* This is inside a link - remove underline */
    &:hover {
        text-decoration: none;
    }
}

.createNewListingLink {
    @apply --marketplaceButtonStyles;
}

.authenticationGreeting {
    /* Font */
    @apply --marketplaceH1FontStyles;

    margin-bottom: 24px;
    margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
    white-space: nowrap;
}

.signupLink {
    text-decoration: none;
    white-space: nowrap;
    color: var(--marketplaceColor);
}
.loginLink {
    text-decoration: none;
    white-space: nowrap;
    color: var(--marketplaceColor);
}

.currentPage {
    color: var(--matterColorDark);

    /* black left border */
    border-left: 5px solid black;
    margin-left: -24px;
    padding-left: 19px;
}
