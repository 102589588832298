.strokeMarketplaceColor {
    stroke: var(--marketplaceColor);
}

.fillSuccessColor {
    fill: var(--successColor);
}

.strokeLightColor {
    stroke: var(--matterColorLight);
}
