@import '../../styles/propertySets.css';

.root {
    width: 100%;
}

.noImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--matterColorNegative);
}

.noImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noImageIcon {
    padding-top: 24px;
    box-sizing: content-box;
    stroke: var(--matterColor);
}

.noImageText {
    @apply --marketplaceH6FontStyles;
    color: var(--matterColor);
    padding-bottom: 24px;

    margin-bottom: 0;
    @media (--viewportMedium) {
        margin-bottom: 0;
    }
}
