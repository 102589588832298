.root {
}

.marketplaceStroke {
    stroke: var(--marketplaceColor);
}

.successFill {
    fill: var(--successColor);
}

.checkStroke {
    stroke: var(--matterColorLight);
}
