.root {
    position: absolute;
    z-index: var(--zIndexPopup);

    background-color: var(--matterColorLight);
    border-top: 1px solid var(--matterColorNegative);
    box-shadow: var(--boxShadowPopup);
    border-radius: 0 0 2px 2px;
    transition: var(--transitionStyleButton);
}

.isClosed {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.isOpen {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.content {
    display: flex;
    flex-direction: column;
    margin: 0;
}

/* Styles for arrow (if arrowPosition is defined) */
.arrowTop,
.arrowBelow {
    content: ' ';
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border: solid transparent;
    pointer-events: none;
}

.arrowTop {
    border-bottom-color: var(--matterColorLight);
    border-width: 7px;
    margin-left: -7px;
}

.arrowBelow {
    border-bottom-color: var(--matterColorNegative);
    border-width: 9px;
    margin-left: -9px;
}
