.root {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    padding: 10px;
    margin-bottom: 30px;

    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #EDEDED;

    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.089px;

    @media (--viewportMedium) {
        font-size: 14px;
    }
}

.dropdownRoot {
    display: flex;
    width: 100%;
    padding: 12px 17px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FFF;
}

.topWrapper {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.bottomWrapper {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.topTitleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}