@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.darkSkin {
    background-color: var(--matterColor);
}

.tab {
    margin-left: 16px;

    &:first-child {
        margin-left: 0;
    }

    @media (--viewportLarge) {
        margin-left: 24px;
    }
}

.tabContent {
    @apply --marketplaceLinkStyles;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    /* Font */
    @apply --marketplaceTabNavHorizontalFontStyles;

    color: var(--matterColor);
    padding-bottom: 10px;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    transition: var(--transitionStyleButton);

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--matterColorDark);
    }

    @media (--viewportMedium) {
        border-bottom-width: 4px;
        padding: 16px 10px 14px 10px;
    }
}

.button {
    /* Override Chrome's border-radius which is visible when
   border is used as underline */
    border-radius: 0;
    /* Override Chrome's button outline */
    outline: 0;
}

.selectedTabContent {
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);
}

.disabled {
    pointer-events: none;
    color: var(--matterColorAnti);
    text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
    color: var(--matterColorAnti);

    &:hover {
        color: var(--matterColorLight);
    }
}

.selectedTabContentDarkSkin {
    border-bottom-color: var(--matterColorLight);
    color: var(--matterColorLight);
}

.disabledDarkSkin {
    color: var(--matterColorDark);
    opacity: 0.3;
}
