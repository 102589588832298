.addOnContainer {
    display: flex;

    padding: 0 20px 20px 0px !important;
    padding-left: 0px;

    margin-bottom: 10px;

    & > div {
        flex-grow: 1;
    }

    @media (--viewportLarge) {
        padding: 30px 40px;
    }
}

.headingComponentRoot {
}

.addOnTitle {
    font-size: 18px;
    font-weight: var(--fontWeightMedium);

    line-height: 24px;

    margin-top: 5px;
}

.secondaryColor {
    color: var(--marketplaceSecondaryColor);
}

.addOnDetailsContainer {
    display: flex;
    flex-wrap: wrap-reverse;

    justify-content: space-between;
}

.addOnInfos {
    /* font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;

   */
    width: 70%;
    padding-right: 5%;

    /* display: flex; */
    flex-grow: 1;

    & > p {
        line-height: 24px;
        font-weight: var(--fontWeightRegular);
    }
}

.addOnPriceDesktopContainer {
    display: none;

    flex-direction: row;

    text-align: right;
    margin-bottom: 16px;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
    }
}

.addOnPriceMobileContainer {
    display: flex;
    flex-direction: row;

    text-align: right;
    margin-top: -8px;
    margin-bottom: 16px;

    @media (--viewportMedium) {
        display: none;
    }
}

.addOnPriceText {
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
}

.addOnPriceText {
    color: var(--marketplaceSecondaryColor);
}

.perHourLabel {
    margin-left: 5px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addOnContainerDivider {
    border-bottom: 1px solid #d9d9d9;
}
