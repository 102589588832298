.activityContainer,
.addOnContainer {
    padding: 20px;

    margin-bottom: 10px;

    @media (--viewportLarge) {
        padding: 30px 40px;
    }
}

.activityContainer {
    position: relative;

    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);
}

.activityInfoContainer {
    margin-top: 40px;
}

.activityInfoTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.activityInfoFieldTitle {
    margin-right: 5px;

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.activityInfoFieldValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.activityInfoColumnField,
.activityInfoRowField,
.activityInfoFieldRowContainer {
    margin: 10px auto;
    padding: 10px 0px;
}

.activityInfoIcon {
    width: 22.5px;
}

.ActivityInfoFieldRowContainer {
}

.activityInfoRowField {
    display: flex;
    flex-direction: row;

    margin: 0px;
}

.activityInfoFieldValuet {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.bottomDivider {
    border-bottom: 1px solid grey;
}

.headingComponentRoot {
}

.infoIcon {
    cursor: pointer;

    position: absolute;
    right: 20px;
    top: 20px;
    @media (--viewportMedium) {
        right: 40px;
        top: 30px;
    }
}

.activityTitle,
.addOnTitle {
    font-size: 18px;
    font-weight: var(--fontWeightMedium);

    line-height: 24px;

    margin-top: 5px;
}

.activityDetailsContainer,
.addOnDetailsContainer {
    display: flex;
    flex-wrap: wrap-reverse;

    justify-content: space-between;
}

.activityInfos,
.addOnInfos {
    /* font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;

   */
    width: 70%;

    display: flex;
    flex-grow: 1;
}

.activityPriceContainer,
.addOnPriceContainer {
    display: flex;
    flex-direction: row;

    text-align: right;
    margin-bottom: 16px;

    @media (--viewportMedium) {
        flex-direction: column;
    }
}

.activityPriceText,
.addOnPriceText {
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
}

.activityPriceText {
    color: var(--marketplaceColor);
}
.addOnPriceText {
    color: var(--grey3);
}

.perHourLabel {
    margin-left: 5px;
    line-height: 16px;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.activityCardDetailsFieldsContainer {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.activityCardDetailsField {
    margin-right: 10px;

    display: flex;
    align-items: center;

    & > img {
        margin-right: 10px;
    }
}

.rowInfoFields {
    width: 100%;
}

.actvitiyDescription {
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    line-height: 24px;
    color: var(--grey2);

    width: 100%;
}

.activityCardDetailsFieldValue {
    color: var(--marketplaceColor);
    margin-left: 5px;
}

.activityDetailsSectionContainer {
}
.activityDetailsSectionBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
}
