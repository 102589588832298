@import '../../styles/customMediaQueries.css';

.root {
    position        : relative;
    width           : 100%;
    padding         : 0;
    background-color: white;

    /* @media (--viewportMedium) {
        padding: 100px 0;
    } */
}

/* Tooltip container */
.tooltip {
    position: relative;
    display : inline-block;
    margin-top  : -2px;
    margin-left : 10px;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility      : hidden;
    background-color: #4A4A4A;
    color           : #fff;
    text-align      : center;
    padding         : 5px;
    border-radius   : 4px;
    font-weight     : 300;
    font-size       : 14px;
    line-height     : 24px;

    width      : 240px;
    bottom     : 100%;
    left       : 50%;
    margin-left: -120px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index : 1;
}

.tooltip .tooltiptext::after {
    content     : " ";
    position    : absolute;
    top         : 100%;
    left        : 50%;
    margin-left : -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4A4A4A transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}