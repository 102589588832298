@import '../../../styles/propertySets.css';

.root {
    z-index: 9;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 18px 24px 18px 16px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
    display: flex;
    flex-direction: column;
    transform: translate3d(0, 0, 0);

    @media (--viewportLarge) {
        display: none;
    }
}

.priceContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: 5px 12px;
    justify-content: center;
}

.priceValue {
    @apply --marketplaceH2FontStyles;
    color: var(--matterColor);
    margin-right: 4px;
    margin-top: 0;
    margin-bottom: 0px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.perUnit {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 0px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.actionButtonsWrapper {
    display: flex;
    width: 100%;
}

.bookButton {
    @apply --marketplaceButtonStylesPrimary;
    width: 100%;
    padding: 0;
}

.modalContainer {
    padding: 60px 20px;
}

.noOfGuest {
    margin-left: 20px;
    font-size: 16px;
}