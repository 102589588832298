@import '../../styles/propertySets.css';

.mobileInputRoot {
    width: 100%;
}

.desktopInputRoot {
    height: var(--topbarHeightDesktop);
}

.mobileIcon {
    margin-left: 24px;
    padding: 11px 0 17px 0;

    /* Borders */
    border-bottom-width: var(--borderRadiusMobileSearch);

    display: flex;
    width: 24px;
    align-self: stretch;
    background-color: var(--matterColorLight);
}

.mobileInput {
    flex-grow: 1;

    /* Font */
    @apply --marketplaceH1FontStyles;

    /* Layout */
    margin: 0 24px 0 0;
    padding: 4px 13px 10px 13px;
    height: var(--TopbarSearchForm_inputHeight);
    line-height: unset;

    /* Borders */
    border-bottom-width: var(--borderRadiusMobileSearch);

    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    &:hover,
    &:focus {
        border-bottom-color: var(--marketplaceColor);
        outline: none;
    }
}

.desktopIcon {
    height: var(--topbarHeightDesktop);
    border: none;
    padding-top: 3px;

    display: flex;
    width: 24px;
    align-self: stretch;
    background-color: var(--matterColorLight);
}

.desktopInput {
    flex-grow: 1;
    /* Font */
    @apply --marketplaceH4FontStyles;
    height: var(--topbarHeightDesktop);
    line-height: unset;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;

    /* Search text gets ellipsis if it's too wide */
    text-overflow: ellipsis;
    overflow-x: hidden;

    &:hover,
    &:focus {
        border-bottom-color: var(--marketplaceColor);
        outline: none;
    }

    &:hover::placeholder,
    &:focus::placeholder {
        color: var(--matterColor);
    }

    /* Placeholder text might be too wide */
    &::placeholder {
        text-overflow: ellipsis;
        overflow-x: hidden;
        font-weight: var(--fontWeightMedium);
        transition: var(--transitionStyleButton);
    }

    margin-top: 0;
    margin-bottom: 0;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: var(--fontWeightSemiBold);
    }
}

.mobilePredictions {
    position: absolute;
    top: calc(
        var(--TopbarSearchForm_inputHeight) -
            var(--TopbarSearchForm_bottomBorder)
    );
    left: 0;
    min-height: calc(
        100vh -
            calc(
                var(--TopbarSearchForm_topbarMargin) +
                    var(--TopbarSearchForm_inputHeight) -
                    var(--TopbarSearchForm_bottomBorder)
            )
    );
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
    /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
    margin-bottom: 100px;
}

.desktopPredictions {
    position: absolute;
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    top: 50px;
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);

    margin-top: calc(
        var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
            var(--TopbarSearchForm_bottomBorder)
    );
    max-width: 434px;
}
