.root {
}

.bookingInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dateSection {
    margin-right: 5px;
}
