.root {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.scrollingDisabled {
    /* position: fixed and width are added to prevent iOS from scrolling content */
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.whatsappBtn {
    z-index: 99;

    & > div {
        margin-bottom: 40px;

        @media (--viewportLarge) {
            margin-bottom: auto;
        }
    }
}
