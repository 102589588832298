.root {
    width: 100%;
    min-height: 120px;
    padding-left: 0;

    &::after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.thumbnail {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    margin: 0;
    overflow: hidden;
    background-color: var(--matterColorNegative); /* Loading BG color */
}

.threeToTwoWrapper {
    position: relative;
}

.removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;

    & svg {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 10px;
        height: 10px;
        fill: var(--matterColorAnti);
        stroke: var(--matterColorAnti);
    }

    &:hover svg {
        fill: var(--matterColorLight);
        stroke: var(--matterColorLight);
    }
}

.aspectWrapper {
    padding-bottom: calc(100% * (2 / 3));
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.thumbnailLoading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(211, 211, 211, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
