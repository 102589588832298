.popupSize {
    padding: 0 10px 7px 10px;
}

.duration {
    padding: 0 24px 24px 24px;
}

.duration {
    padding: 0 24px;
    font-size: 12px;
    margin-top: 0px;
}
