@import '../../styles/propertySets.css';

.root {
    @apply --marketplaceModalFormRootStyles;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
    }
}

.name {
    display: flex;
    justify-content: space-between;
    @media (--viewportMedium) {
        
    }
}

.firstNameRoot {
    width: calc(34% - 9px);
}

.lastNameRoot {
    width: calc(66% - 9px);
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
    text-align: start;
}

.termsText {
    @apply --marketplaceModalHelperText;
}

.termsLink {
    @apply --marketplaceModalHelperLink;

    /* &:hover {
        text-decoration: underline;
        cursor: pointer;
    } */
}

/* --- Asher add CSS --- */

.phoneNum, .email, .birthDay {
    margin-top: 32px;
}
