/* .root {} */

.topActionsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
}

.navWrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin: 24px 0;

    font-size: 14px;
    font-weight: 600;
    line-height: 42.5px;
}

.title {
    margin: 0 0 8px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 42.5px;
}

.subTitle {
    margin: 0 0 40px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.sectionTitle {
    margin: 0 0 20px 0;
    color: #B2B2B2;
    font-size: 18px;
    font-weight: 600;
    line-height: 42.5px;
}

.generalInput {
    margin-bottom: 30px;
    margin-top: 30px;
}

.customCheckbox>label {
    margin: 20px 0 4px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.tncCheckbox>label {
    margin: 0 0 40px 0;

    &>span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}

.smallDesc {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 38px;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 46px;
}

.nextButton {
    padding: 15px 47px;
    width: unset;
    float: right;
}

.datesWrapper {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
}

.fieldDateInput {
    flex-grow: 1;

    & :global(.SingleDatePickerInput) {
        width: 100%;
        margin: 0;
    }

    & :global(.SingleDatePicker_picker__directionLeft) {
        width: 100vw;

        @media (--viewportMedium) {
            width: calc(200% + 24px);
        }
    }

    &>label {
        margin: 0;
    }
}

.fieldSelect {
    flex-grow: 1;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 28px;
        left: 0px;
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
        background-size: 12px 12px;
        width: 12px;
        height: 12px;
    }

    @media (--viewportMedium) {
        &::after {
            top: 38px;
        }
    }
}

.fieldSelectDisabled {
    composes: fieldSelect;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 28px;
        left: 0px;
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
        background-size: 12px 12px;
        width: 12px;
        height: 12px;
    }

    @media (--viewportMedium) {
        &::after {
            top: 38px;
        }
    }
}

.select {
    position: relative;
    padding-left: 24px;
    padding-right: 16px;
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 10px 6px;
    border-bottom-width: 2px;

    @media (--viewportMedium) {
        border-bottom-width: 3px;
    }
}

.selectDisabled {
    composes: select;
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.timeAndIconWrapper {
    position: relative;
    width: calc(50% - 10px);
}

.addOnCard {
    padding: 20px !important;
    border-radius: 4px;
    border: 1px solid var(--Light-Grey, #DBDBDB);
}

.createNewAddon {
    cursor: pointer;
    margin-top: 30px;
    color: #30A64A;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.addonBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
}

.reviewInfoList {
    list-style: unset;

    &>li {
        color: #444446;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.089px;
    }
}

.cancellationWrapper {
    position: relative;
}

.cancellationWrapper:hover .cancellationToolTip {
    visibility: visible;
}

.cancellationToolTip {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 200px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    bottom: -155px;
    right: -15px;
    text-align: center;
}

.cancellationToolTip:before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #192733;
    padding: 5px;
    z-index: 1;
    top: -5px;
    right: 18px
}

.uploadPDF {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    width: unset;

    font-size: 14px;
    font-weight: 600;
}
