@import '../../styles/propertySets.css';

.root {
    @apply --marketplaceModalFormRootStyles;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
    }
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
    @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
    @apply --marketplaceModalHelperText;
}
