@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: column;
}

.bookingDates {
    flex-shrink: 0;
    margin-bottom: 38px;

    /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
    z-index: 1;
}

.priceBreakdownContainer {
    padding: 0 24px;
    margin-bottom: 24px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 0;
        margin-top: 1px;
    }
}

.priceBreakdownTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin-top: 5px;
    margin-bottom: 14px;
    @media (--viewportMedium) {
        margin-top: 5px;
        margin-bottom: 26px;
    }
}

.receipt {
    flex-shrink: 0;
    margin: 0 0 24px 0;
}

.error {
    color: var(--failColor);
    margin: 0 24px;
    display: inline-block;
}

.spinner {
    margin: auto;
}

.sideBarError {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    margin: 0 24px 12px 24px;

    @media (--viewportMedium) {
        margin: 0 0 12px 0;
    }
}

.smallPrint {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColorAnti);
    text-align: center;
    margin: auto 24px 20px 24px;
    flex-shrink: 0;

    @media (--viewportMedium) {
        margin-top: auto;
        margin-bottom: 20px;
    }

    @media (--viewportLarge) {
        margin-top: 4px;
        margin-bottom: 21px;
    }
}

.submitButtonWrapper,
.instantBookingBtn {
    flex-shrink: 0;
    padding: 0 24px 24px 24px;
    width: 100%;
 

    @media (--viewportMedium) {
        padding: 0;
        width: 100%;
    
    }
}

.infoIconWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addOnLabel{
    width: 270px;
    @media (--viewportMedium) {
        width: 260px;
    
    }
    
}

.hovertext {
    position: relative;
     float: right; 
     display: inline;
    
     
   
  }
  

  .hovertext:before {

    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    min-width: 250px !important;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 0.1s ease-in-out;
    position: absolute;
    z-index: 1;
    right: -100%;
    font-size: 12px;
    bottom: 110%;
  
    @media (--viewportMedium) {
        content: attr(data-hover);
        visibility: hidden;
        opacity: 0;
        min-width: 200px !important;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 5px;
        padding: 5px 5px;
        transition: opacity 0.1s ease-in-out;
        position: absolute;
        z-index: 1;
        left: 10%;
        font-size: 12px;
        bottom: 110%;
    }
    
  }
  
  .hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }
  



.instantBookingBtn {
    margin-top: 26px;

    color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);


    &:hover {
        color: var(--marketplaceColorDark);
        border-color: var(--marketplaceColorDark);
    }
}

.contactLink {
    /* @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    color: var(--grey3) !important; */
    border: none;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 0px;
    margin-top: 24px;
    cursor: pointer;
    background-color: #A020F0;
    color: white;

    &:hover{
        background-color:#880ED4 ;
    }
}
.chatText{
    text-align: center;
    font-size: 14px;
}

.addOn {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 8px;

    & > span > label > span {
        font-weight: 400;
    }
}

.addOnQty {
    margin-bottom: 20px;
    margin-left: 38px;
}

.activity,
.addOns,
.noOfGuest,
.maxNoOfGuestInfo {
    margin: 0px 24px 30px;

    @media (--viewportMedium) {
        margin: 0px 0px 30px;
    }
}

.minHourOfBookingInfo,
.maxNoOfGuestInfo {
    color: var(--grey1);
    font-size: 14px;
    line-height: 24px;
}

.minHourOfBookingInfo,
.maxNoOfGuestInfo {
    margin-top: -10px;
}

.requestToBookButtonContainer {
    padding: 0 24px;
    padding-bottom: 6rem;

    @media (--viewportMedium) {
        padding: 0;
        padding-bottom: 0;
    }
}