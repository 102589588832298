@import '../../styles/propertySets.css';

.root {
    /* Size from content */
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    border: 0;

    &:hover {
        & .details,
        & .caret {
            cursor: pointer;

            /* Same as active */
            background-color: var(--marketplaceColor);
            border-color: var(--marketplaceColor);
            color: var(--matterColorLight);
            box-shadow: var(--boxShadowPopup);
        }
    }
}

.details {
    /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
    position: relative;

    /* Font */
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;

    padding: 6px 14px;

    /* Coloring */
    background-color: var(--matterColorLight);
    color: var(--matterColor);

    /* Borders */
    border-radius: 17px;
    border-style: solid;
    border-color: var(--matterColorNegative);
    border-width: 1px;
    box-shadow: var(--boxShadowPopupLight);

    transition: var(--transitionStyleButton);

    /* Overwrite dimensions from font styles */
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.detailsActive {
    /* Same as hover */
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    box-shadow: var(--boxShadowPopup);
}

.caretShadow {
    /* Caret / arrow dimensions and position */
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    transform: rotate(45deg);

    /* Caret should have same box-shadow as label */
    box-shadow: var(--boxShadowPopupLight);
}

.caret {
    /* Caret / arrow dimensions and position */
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    transform: rotate(45deg);

    /* Caret should have same bg-color as label */
    background-color: var(--matterColorLight);

    transition: var(--transitionStyleButton);
}

.caretActive {
    /* Same as hover */
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
}
