.title {
    margin: 0 0 8px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 42.5px;
}

.generalInput {
    margin-bottom: 30px;
    margin-top: 30px;
}

.nextButton {
    margin-top: 24px;
    background-color: #D10000;
    padding: 15px 47px;
    width: unset;
    float: right;

    &:disabled {
        background-color: silver;
    }
}