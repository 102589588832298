@import '../../styles/propertySets.css';

.root {
    position: relative;
}

.input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    /* Display the "check" when checked */
    &:checked + label .checked {
        display: inline;
    }

    /* Highlight the borders if the checkbox is hovered, focused or checked */
    &:hover + label .notChecked,
    &:hover + label .required,
    &:focus + label .notChecked,
    &:focus + label .required,
    &:checked + label .required {
        stroke: var(--matterColorDark);
    }

    &:checked + label .notChecked {
        stroke: none;
    }

    /* Hightlight the text on checked, hover and focus */
    &:focus + label .text,
    &:hover + label .text,
    &:checked + label .text {
        color: var(--matterColorDark);
    }
}

.label {
    display: flex;
    align-items: center;
    padding-top: 6px;
}

.radioButtonWrapper {
    /* This should follow line-height */
    height: 32px;
    margin-top: -2px;
    margin-right: 12px;
    align-self: baseline;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkedStyle {
    stroke: var(--marketplaceColor);
    fill: var(--marketplaceColor);
}

.checked {
    display: none;
}

.notChecked {
    stroke: var(--matterColorAnti);
    &:hover {
        stroke: pink;
    }
}

.required {
    stroke: var(--attentionColor);
    &:hover {
        stroke: pink;
    }
}

.text {
    @apply --marketplaceListingAttributeFontStyles;
    color: var(--matterColor);
    margin-top: -1px;
    margin-bottom: 1px;
    cursor: pointer;
}
