.root {
}

.marketplaceStroke {
    stroke: var(--marketplaceColor);
}

.attentionStroke {
    stroke: var(--attentionColor);
}
