@import '../../styles/propertySets.css';

.root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--matterColorBright);
}

.filtersWrapper {
}

.footer {
    display: flex;
    margin-top: 8px;
}

.resetAllButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    margin: 0 auto 0 0;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        color: var(--matterColor);
    }
}

.cancelButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    margin: 0 24px 0 24px;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        color: var(--matterColor);
    }
}

.applyButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);

    /* Layout */
    margin: 0;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        color: var(--marketplaceColorDark);
    }
}
