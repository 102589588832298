@import '../../styles/propertySets.css';

.root {
    margin: 0;

    /* Clearfix */
    @apply --clearfix;
}

.messageItem {
    margin-bottom: 20px;

    /* Clearfix */
    @apply --clearfix;

    @media (--viewportMedium) {
        margin-bottom: 17px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.message,
.ownMessage {
    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
    max-width: 433px;
}

.message {
    display: flex;
    flex-direction: row;
}

.ownMessage {
    float: right;
}

.avatar {
    flex-shrink: 0;
    margin: 0px 12px 0 0;

    @media (--viewportMedium) {
        margin: 0px 12px 0 0;
    }
}

.ownMessageContentWrapper {
    @apply --clearfix;
}

.messageContent,
.ownMessageContent,
.systemMessageContent {
    @apply --marketplaceMessageFontStyles;

    display: inline-block;
    margin: 0;
    padding: 8.5px 14px 8.5px 14px;
    border-radius: 8px;
    box-shadow: var(--boxShadow);

    @media (--viewportMedium) {
        padding: 8.5px 14px 8.5px 14px;
        margin: 0;
    }
}

.messageContent {
    flex: 1;
}

.messageContent {
    background-color: var(--purpleColor);
    color: var(--matterColorLight);
}

.ownMessageContent {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    float: right;
}

.systemMessageContent {
    background-color: var(--attentionColorLight);
    color: var(--matterColorBright);
    margin-top: 20px;
}

.messageDate,
.ownMessageDate {
    @apply --marketplaceMessageDateFontStyles;

    margin: 11px 0 0 0;
    color: var(--matterColorAnti);

    @media (--viewportMedium) {
        margin: 6px 0 0 0;
    }
}

.ownMessageDate {
    text-align: right;
}

.transitionItem {
    margin-bottom: 18px;

    /* Clearfix */
    @apply --clearfix;

    @media (--viewportMedium) {
        margin-bottom: 16px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.transition {
    display: flex;
    flex-direction: row;
    padding: 3px 0 2px 0;

    @media (--viewportMedium) {
        padding: 0;
    }
}

.transitionContent,
.emphasisTransitionContent,
.acceptedTransitionContent {
    @apply --marketplaceTxTransitionFontStyles;
    margin: 0;
}

.emphasisTransitionContent {
    color: var(--failColor);
}

.acceptedTransitionContent {
    color: var(--successColor);
}

.transitionDate {
    @apply --marketplaceMessageDateFontStyles;
    color: var(--matterColorAnti);
    margin: 7px 0 0 0;

    @media (--viewportMedium) {
        margin: -1px 0 1px 0;
    }
}

.bullet {
    margin-right: 6px;
}

.reviewContent {
    @apply --marketplaceH4FontStyles;
    font-style: italic;
    white-space: pre-line;
    margin: 8px 0 0 0;

    @media (--viewportMedium) {
        max-width: 500px;
        margin: 7px 0 0 0;
    }
}

.reviewStars {
    display: block;
    margin-top: 4px;

    @media (--viewportMedium) {
        margin: 5px 0;
    }
}

.reviewStar {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    font-styles: initial;
}

.showOlderWrapper {
    text-align: center;
    margin-bottom: 1px;

    @media (--viewportMedium) {
        margin-bottom: 0;
    }
}

.showOlderButton {}

.sysMsgRoot {
    display: flex;
    width: 100%;
    padding: 20px;
    margin: 12px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #EDEDED;
}

.sysMsgParagraph {
    color: #444446;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.sysMsgAcions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px;
    gap: 10px;
}

.generalActionButton {
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 60px;
    border: 1px solid #B2B2B2;
    background: #FFF;
    box-shadow: 0px 4px 9.1px 0px rgba(0, 0, 0, 0.09);
    font-size: 14px;
}

.sysMsgSubtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.089px;
    margin-bottom: 16px;

    &>div {
        display: flex;
        align-items: center;
    }
}

.sysMsgMiddleText {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.offerRoot {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #EDEDED;
    margin: 20px 0;
}

.offerHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #444446;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.089px;

    &>svg {
        cursor: pointer;
    }
}

.reactivateOfferLink {
    padding-left: 12px;
}

.offerExpiry {
    margin-top: -14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.offerNote {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.fileRoot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fileContainer {
    border-radius: 8px;
    background: var(--Primary-White, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    padding: 10px;
}

.fileIconWrapper {
    width: 32px;
    height: 32px;
    background-color: #D9D9D9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
}

.fileInfo {
    color: #3D3D3D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &>a {
        text-decoration: none;
        color: #30A64A;
        font-size: 13px;
        display: block;
        margin-top: -6px;
    }
}

.offerBreakdown {
    background-color: #ffffff;
    border-radius: 4px;
    width: 100%;

    &>div {
        width: calc(100% - 40px);
        margin: 20px;
        padding-bottom: 0;
    }
}

.offerActions {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (--viewportMedium) {
        flex-direction: row;
        align-items: center;
    }
}

.uploadPDF {
    margin-top: 20px;
    display: inline-flex;
    width: unset;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    border: 1px solid #B2B2B2;
    background: #FFF;

    color: #4A4A4A;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}