.root {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    column-gap: 32px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
}

.leftPanel {}

.rightPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 66px;
}

.customAddonTitle {
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    color: var(--marketplaceSecondaryColor);
}

.title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.089px;
}

.desc {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.089px;
}

.actions {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &>div>svg {
        cursor: pointer;
    }
}

.price {
    color: #8E50AB;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.089px;
    margin-top: 10px;
}

.charge {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.089px;
}