@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.card {
    @apply --marketplaceInputStyles;

    /* Layout */
    padding: 8px 0 6px 0;
    height: 36px;

    /* Border */
    border-bottom-color: var(--attentionColor);

    @media (--viewportMedium) {
        height: 35px;
    }
    @media (--viewportLarge) {
        height: 38px;
        padding: 6px 0 14px 0;
    }
}

.cardSuccess {
    border-bottom-color: var(--successColor);
}

.cardError {
    border-bottom-color: var(--failColor);
}

.error {
    color: var(--failColor);
}

.errorMessage {
    margin-top: 24px;
    color: var(--failColor);
}

.paymentHeading {
    margin: 0 0 14px 0;
    color: var(--matterColorAnti);

    padding-top: 8px;
    padding-bottom: 0px;

    @media (--viewportMedium) {
        margin: 0 0 26px 0;
    }
}

.billingHeading {
    margin: 0 0 14px 0;
    color: var(--matterColorAnti);

    padding-top: 3px;
    padding-bottom: 2px;

    @media (--viewportMedium) {
        margin: 0 0 26px 0;
    }
}

.paymentLabel {
    margin: 0;

    @media (--viewportMedium) {
        margin-bottom: 2px;
    }
    @media (--viewportLarge) {
        margin-bottom: 4px;
    }
}

.messageHeading {
    color: var(--matterColorAnti);
    margin: 40px 0 14px 0;

    padding-top: 4px;
    padding-bottom: 4px;

    @media (--viewportMedium) {
        margin: 41px 0 26px 0;
    }
    @media (--viewportLarge) {
        margin: 40px 0 26px 0;
    }
}

.messageLabel {
    margin: 0 0 5px 0;

    @media (--viewportMedium) {
        margin: 0 0 6px 0;
    }
}

.messageOptional {
    color: var(--matterColorAnti);
}

.message {
    border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
    margin-top: auto;
    margin-top: 48px;
    @media (--viewportLarge) {
        margin-top: 72px;
    }
}

.infoText {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColorAnti);
    padding: 0;
    margin: 14px 0 0 0;
}

.submitButton {
    margin-top: 22px;

    @media (--viewportMedium) {
        margin-top: 26px;
    }
    @media (--viewportLarge) {
        margin-top: 17px;
    }
}

.missingStripeKey {
    color: var(--failColor);
}

.paymentAddressField {
    padding-top: 38px;
}

.formRow {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 24px;
}

.postalCode {
    margin-top: 24px;
    width: calc(40% - 9px);
}

.city {
    margin-top: 24px;
    width: calc(60% - 9px);
}

.field {
    margin-top: 24px;
}
