.root {
    /* Dimensions */
    width: 100%;
    height: auto;

    /* Display format */
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (--viewportMedium) {
        padding-top: 2px;
    }
}

.error {
    color: var(--failColor);
}

.locationAutocompleteInput {
    flex-grow: 1;
    height: 36px;
    line-height: unset;
    padding-left: 0;
    margin: 0;
    border-bottom: 2px solid var(--attentionColor);

    &:hover,
    &:focus {
        border-bottom-color: var(--matterColorDark);
        outline: none;
    }

    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    @media (--viewportMedium) {
        height: 40px;
    }
}

.locationAutocompleteInputIcon {
    display: none;
}

.predictionsRoot {
    position: absolute;
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    top: 36px;
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);

    @media (--viewportMedium) {
        top: 40px;
    }
}

.validLocation {
    border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
    flex-shrink: 0;
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;

    @media (--viewportLarge) {
        display: inline-block;
        width: 241px;
        margin-top: 100px;
    }
}
