@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
    0% {
        visibility: hidden;
        opacity: 0;
        top: -20px;
    }
    100% {
        visibility: visible;
        opacity: 1;
        top: 0;
    }
}

/* Shared animation rules */
.animation {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 3s;

    visibility: hidden;
    opacity: 1;
    position: relative;
}

.root {
    @apply --backgroundImage;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    /* Text positioning is done with paddings */
}

.heroContent {
    margin: 0 24px 0 24px;

    @media (--viewportMedium) {
        margin: 0 7.5vw 0 7.5vw;
    }

    /* Special viewport for adjusting the heroContent's placement */

    @media only screen and (min-width: 1025px) and (max-width: 1366px) {
        padding: 0 36px 0 36px;
    }

    @media only screen and (min-width: 1367px) {
        margin: 0 auto;
        max-width: calc(1052px + 36px + 36px);
        width: 100%;
        padding: 0 36px 0 36px;
    }
}

.heroMainTitle {
    @apply --marketplaceHeroTitleFontStyles;
    color: var(--matterColorLight);
    composes: animation;

    @media (--viewportMedium) {
        max-width: var(--SectionHero_desktopTitleMaxWidth);
    }
}
.heroMainTitleFEDelay {
    animation-delay: 0s;
}

.heroSubTitle {
    @apply --marketplaceH4FontStyles;

    color: var(--matterColorLight);
    margin: 0 0 32px 0;
    composes: animation;

    @media (--viewportMedium) {
        max-width: var(--SectionHero_desktopTitleMaxWidth);
        margin: 0 0 47px 0;
    }
}
.heroSubTitleFEDelay {
    animation-delay: 0.15s;
}

.heroButton {
    @apply --marketplaceButtonStyles;
    composes: animation;

    @media (--viewportMedium) {
        display: block;
        width: 260px;
    }
}
.heroButtonFEDelay {
    animation-delay: 0.3s;
}
