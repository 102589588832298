@import '../../styles/propertySets.css';

.root {
    display: none;

    /* Borders */
    outline: none;
}

.popup {
    /* By default hide the content */
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    /* Position */
    position: absolute;
    z-index: var(--zIndexPopup);

    /* Layout */
    padding: 15px 30px 20px 30px;
    margin-top: 7px;
    background-color: var(--matterColorLight);

    /* Borders */
    border-top: 1px solid var(--matterColorNegative);
    box-shadow: var(--boxShadowPopup);
    border-radius: 4px;
    transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.plain {
    width: 100%;
}

.isOpen {
    display: block;
}

.contentWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
}

@media (--viewportMedium) {
    .contentWrapper {
        padding-top: 0px;
        padding-bottom: 8px;
        margin-top: 0px;
    }
}

.label {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    padding: 11px 0 13px 0;
    margin: 0 18px 0 0;
}

@media (--viewportMedium) {
    .label {
        padding: 8px 0 16px 0;
    }
}

.inputsWrapper {
    display: flex;

    @media (--viewportMedium) {
        padding-bottom: 5px;
    }
}

.minPrice {
    @apply --marketplaceSearchFilterSublabelFontStyles;
    width: 48px;
    text-align: center;
    border-bottom-color: var(--attentionColor);
    border-bottom-width: 3px;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (--viewportMedium) {
        padding: 1px 0 4px 0;
    }
}

.maxPrice {
    @apply --marketplaceSearchFilterSublabelFontStyles;
    width: 48px;
    text-align: center;
    border-bottom-color: var(--attentionColor);
    border-bottom-width: 3px;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (--viewportMedium) {
        padding: 1px 0 4px 0;
    }
}

.priceSeparator {
    margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
    .priceSeparator {
        margin: 2px 8px 0 8px;
    }
}

.sliderWrapper {
    display: flex;
    padding: 17px 0 25px 0;
}

@media (--viewportMedium) {
    .sliderWrapper {
        padding: 16px 0 24px 0;
    }
}

.buttonsWrapper {
    display: flex;
}

.clearButton {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    margin: 0 auto 0 0;
    padding: 0;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }
}

.cancelButton {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    margin: 0;
    padding: 0;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
    margin-left: 48px;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }
}

.submitButton {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--marketplaceColor);

    /* Layout */
    margin: 0 0 0 19px;
    padding: 0;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--marketplaceColorDark);
        transition: width var(--transitionStyleButton);
    }
}

.disabled {
    color: var(--matterColorAnti);
    cursor: not-allowed;

    &:hover {
        color: var(--matterColorAnti);
    }
}