@import '../../styles/propertySets.css';

.inputRoot {
    /*
    Calendar component using react-dates has automatically padding so
    negative margin to left and right is needed for element to fit on smaller screens.
  */

    margin: 0px -20px;

    @media (--viewportMedium) {
        margin: 0;
    }

    & :global(.CalendarMonthGrid) {
        background-color: transparent;
    }

    & :global(.DayPicker__horizontal) {
        margin: 0 auto;
        box-shadow: none;
        background-color: transparent;
    }

    & :global(.DayPickerNavigation__horizontal) {
        position: relative;
        width: 100%;
    }

    & :global(.DayPickerNavigation__bottom) {
        display: none;
        &:first-of-type {
            display: block;
        }
    }

    & :global(.DayPickerNavigation_button__horizontal) {
        padding: 6px 9px;
        top: 21px;
        position: absolute;
        cursor: pointer;
        display: inline;
        &:first-of-type {
            left: 24px;
        }
        &:last-of-type {
            right: 24px;
        }

        @media (--viewportMedium) {
            top: 18px;
        }
    }
    & :global(.DayPickerNavigation_button) {
        color: var(--matterColor);
        border: 0;
    }

    & :global(.CalendarMonth),
    & :global(.CalendarMonthGrid) {
        background-color: transparent;
    }

    & :global(.DayPicker_weekHeader) {
        color: var(--matterColor);
        top: 62px;
    }

    & :global(.DayPicker_weekHeader_li) {
        @apply --marketplaceH5FontStyles;

        margin-top: 0;
        margin-bottom: 0;

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    & :global(.DayPicker_weekHeader_li small) {
        font-size: 100%;
    }

    & :global(.CalendarMonth_caption) {
        color: var(--matterColor);
        @apply --marketplaceH3FontStyles;
        margin: 1px 0 14px;
        font-weight: 400;
        line-height: 20px;
        padding-top: 31px;
        padding-bottom: 37px;

        &::first-letter {
            text-transform: capitalize;
        }

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    & :global(.CalendarDay__default) {
        border: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        background: transparent;
    }

    & :global(.CalendarDay) {
        @apply --marketplaceH4FontStyles;
        color: var(--matterColor);
        border: 0;
        margin-top: 0;
        margin-bottom: 0;

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    /* Add an underline for '.renderedDay' */
    & :global(.CalendarDay__today .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234A4A4A' fill-rule='evenodd'/></svg>");
        background-position: center 28px;
    }

    & :global(.CalendarDay__today.CalendarDay__selected .renderedDay) {
        background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    }

    & :global(.CalendarDay:hover .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--matterColorNegative);
        color: var(--matterColor);
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__hovered_span),
    & :global(.CalendarDay__selected_span) {
        background-image: transparent;
        background-color: transparent;
    }
    & :global(.CalendarDay__hovered_span .renderedDay),
    & :global(.CalendarDay__selected_span .renderedDay),
    & :global(.CalendarDay__hovered_span:hover .renderedDay),
    & :global(.CalendarDay__selected_span:hover .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--successColor);
        color: var(--matterColorLight);
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__selected_start) {
        background-color: transparent;
        background-image: none;
    }
    & :global(.CalendarDay__selected_start .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--successColor);
        color: var(--matterColorLight);
        border-top-left-radius: calc(
            var(--DateRangeController_selectionHeight) / 2
        );
        border-bottom-left-radius: calc(
            var(--DateRangeController_selectionHeight) / 2
        );
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__selected_end) {
        background-color: transparent;
    }
    & :global(.CalendarDay__selected_end .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--successColor);
        color: var(--matterColorLight);
        border-top-right-radius: calc(
            var(--DateRangeController_selectionHeight) / 2
        );
        border-bottom-right-radius: calc(
            var(--DateRangeController_selectionHeight) / 2
        );
    }
    & :global(.CalendarDay:hover.CalendarDay__selected_start .renderedDay),
    & :global(.CalendarDay:hover.CalendarDay__selected_span .renderedDay),
    & :global(.CalendarDay:hover.CalendarDay__selected_end .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--successColor);
        color: var(--matterColorLight);
    }

    & :global(.CalendarDay__selected_span .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateRangeController_selectionHeight);
        background-color: var(--successColor);
        color: var(--matterColorLight);
        transition: all 0.2s ease-out;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__blocked_out_of_range .renderedDay),
    & :global(.CalendarDay__blocked_out_of_range:hover .renderedDay) {
        background-color: transparent;
        color: var(--matterColorAnti);
        text-decoration: line-through;
        border: 0;
    }
}

.arrowIcon {
    stroke: var(--marketplaceColor);
    fill: var(--marketplaceColor);
}
