@import '../../styles/propertySets.css';

.root {
    /* Layout */
    width         : 100%;
    height        : 100%;
    display       : flex;
    flex-direction: unset;

    /* background: url('../../assets/background-0704.png');
    @apply --FixedBackgroundImage; */

    /* Content of EditListingWizard should have smaller z-index than Topbar */
    z-index: 0;
}

.tabsContainer {
    flex-grow: 1;
    /* Layout */
    width    : 100%;
    height   : 100%;

    /* display: flex; */
    display       : block;
    flex-direction: column;

    @apply --PageLayout40Img60Content;
    width     : 75%;
    align-self: flex-start !important;

    @media (--viewportLarge) {
        flex-direction : row;
        justify-content: center;
    }
}

.nav {
    /* Layout */
    /* display: flex; */
    display              : grid;
    grid-template-columns: repeat(6, auto);
    justify-items        : start;
    justify-content      : start;
    grid-column-gap      : 5%;

    flex-direction  : row;
    padding         : 0;
    flex-shrink     : 0;
    background-color: var(--matterColorLight);
    box-shadow      : var(--boxShadowLight);
    border-top      : 1px solid var(--matterColorNegative);
    overflow-x      : scroll;

    @media (--viewportLarge) {
        /* padding: 128px 0 82px 36px; */
        padding         : 5% calc((100% - 1056px) / 2) 0 59px;
        flex-direction  : column;
        overflow-x      : auto;
        background-color: var(--matterColorLight);
        box-shadow      : none;
        border-top      : none;

        margin-bottom: 58px;
    }

    @media (--viewportLargeWithPaddings) {
        /* padding: 128px 0 82px calc((100% - 1056px) / 2); */
    }
}

.nav a {
    width           : auto;
    font-size       : 14px !important;
    background-image: none !important;
    text-align      : center;
}

.tab {
    margin-left: 16px;
    white-space: nowrap;

    &:first-child {
        margin-left: 0;

        /* Padding added to tab so that it will be visible after call to scrollToTab */
        padding-left: 24px;
    }

    &:last-child {
        /* Padding added to tab so that it will be visible after call to scrollToTab */
        padding-right: 24px;
    }

    @media (--viewportLarge) {
        margin-left: 0;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.panel {
    flex-grow: 1;
    display  : block;

    @media (--viewportLarge) {
        padding         : 0 36px 82px 59px;
        border-left     : 1px solid var(--matterColorNegative);
        background-color: var(--matterColorLight);
    }

    @media (--viewportLargeWithPaddings) {
        padding: 0 calc(70% - 528px) 82px 59px;
    }
}

.payoutDetails {
    margin-bottom: 100px;

    @media (--viewportMedium) {
        margin-bottom: 0;
    }
}

.modalTitle {
    @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
    @media (--viewportMedium) {
        width      : 604px;
        padding-top: 11px;
    }
}

.modalMessage {
    @apply --marketplaceModalParagraphStyles;
}

.tab a {
    border-bottom: none !important;
}

.leftImgPost {
    background-color     : var(--matterColor) !important;
    background-size      : auto 100% !important;
    background-position  : calc(100vh - 150vh) 0 !important;
    background-repeat    : no-repeat !important;
    background-attachment: fixed !important;
    height               : auto;
    width                : 31%;
    /* flex-direction    : row; */
    /* justify-content   : center; */
}

.desimg {
    background: url(/src/assets/1-details.png);
}

.feaimg {
    background: url(/src/assets/2-amenities.png);
}

.actimg {
    background: url(/src/assets/3-activity.png);
}

.phoimg {
    background         : url(/src/assets/4-photo.png);
    background-position: calc(100vh - 200vh) 0 !important;
}

.avaimg {
    background: url(/src/assets/5-availability.png);
}

.priimg {
    background: url(/src/assets/6-pricing.png);
}