.input {
    border-bottom-color: var(--attentionColor);
}

.inputSuccess {
    border-bottom-color: var(--successColor);
}

.inputError {
    border-bottom-color: var(--failColor);
}
