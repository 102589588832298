@import '../../styles/propertySets.css';

.root {
    position: relative;
}

.input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    

    /* Highlight the borders if the checkbox is hovered, focused or checked */
    &:hover + label .box,
    &:focus + label .box,
    &:checked + label .box {
        stroke: var(--marketplaceColor);
    }

    /* successColor version */
    &:hover + label .boxSuccess,
    &:focus + label .boxSuccess,
    &:checked + label .boxSuccess {
        stroke: var(--successColor);
    }

    /* Display the "check" when checked */
    &:checked + label .checked {
        display: inline;
        fill: var(--marketplaceColor);
        background: var(--marketplaceColor);
    }

    /* Display the "check" when checked */
    &:checked + label .checkedSuccess {
        display: inline;
        stroke: var(--successColor);
        stroke-width: 1px;
    }

    /* Hightlight the text on checked, hover and focus */
    &:focus + label .text,
    &:hover + label .text,
    &:checked + label .text {
        color: var(--matterColorDark);
    }
}

.label {
    display: flex;
    align-items: center;
    padding: 0;
}

.checkboxWrapper {
    /* This should follow line-height */
    height: 20px;
    margin-top: 0px;
    margin-right: 12px;
    align-self: baseline;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checked {
    fill: #ffffff;
    width: 25px;
    height: 25px;
    border: 1px solid var(--grey1);
}
.checkedSuccess {
    display: none;
    fill: var(--successColor);
}

.boxSuccess,
.box {
    stroke: var(--matterColorAnti);
}

.text {
}
.textRoot {
    @apply --marketplaceListingAttributeFontStyles;
    color: var(--matterColor);
    margin-top: -1px;
    margin-bottom: 1px;
    cursor: pointer;
}
