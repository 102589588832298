@import '../../styles/propertySets.css';

.root {
    /* Layout */
    display: flex;
    flex-direction: column;
    transition: var(--transitionStyleButton);

    /* Remove link's hover effect */
    &:hover {
        text-decoration: none;
        transform: scale(1.02);
    }
}

.listCard {
    box-shadow: 0px 0px 14px rgb(0 0 0 / 7%);
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
    //transition: var(--transitionStyleButton);

    &:hover {
        //transform: scale(1.02);
        //box-shadow: var(--boxShadowListingCard);
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    padding-bottom: 66.6667%;
    /* 3:2 Aspect Ratio */
    background: var(--matterColorNegative);
    /* Loading BG color */
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: var(--borderRadius);
}

.info {
    /* Layout */
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;

    @media only screen and (--viewportLarge) {
        padding: 10px 15px 20px 15px;
    }

    @media only screen and (--viewportLargeWithPaddings) {
        padding: 15px 20px 20px 20px;
    }
}

.priceFromText {
    color: #8e50ab;
    margin: 0;
    margin-bottom: -8px;
    font-weight: 400;
    font-size: 14px;
}

.price {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    align-items: flex-start;
    margin-top: 5%;

    width: fit-content;
    align-self: end;

    @media only screen and (--viewportMedium) {
        margin-top: 3%;
    }
}

.priceUnit {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.priceValue {
    /* Font */
    @apply --marketplaceH3FontStyles;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.mainInfo {
    display: flex;
    flex-direction: column;
}

.title {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 5px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    @media (--viewportLarge) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.certificateInfo {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.instantBookLabel {
    position: absolute;
    background: var(--matterColorNegative);
    font-size: 14px;
    color: var(--grey3);
    font-weight: 400;
    margin: 10px 0 0 10px;
    padding: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
}

.listInfo {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    justify-content: start;
    font-size: 14px;
    font-weight: 400;
    color: var(--grey2);

    @media only screen and (--viewportMedium) {
        grid-gap: 15px;
        font-size: 12px;
    }

    @media only screen and (--viewportLarge) {
        grid-gap: 10px;
        font-size: 12px;
    }

    @media only screen and (--viewportLargeWithPaddings) {
        grid-gap: 20px;
        font-size: 14px;
    }
}

.listInfoItem {
    display: grid;
    grid-auto-flow: column;
    align-items: start;
    line-height: 1.3em;

    &>svg {
        height: 16px;
    }
}

.listInfoItem:nth-child(2) {
    /* white-space: nowrap;
    overflow: hidden; */
}

.listInfoItem img {
    margin-top: 3px;
    margin-right: 10px;

    @media only screen and (--viewportMedium) {
        margin-top: 1px;
        margin-right: 10px;
    }

    @media only screen and (--viewportLarge) {
        margin-right: 5px;
    }

    @media only screen and (--viewportLargeWithPaddings) {
        margin-top: 3px;
        margin-right: 10px;
    }
}

.lastImage {
    width: 100%;
}