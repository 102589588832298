@import '../../styles/propertySets.css';

.root {
}

.heading {
    @apply --marketplaceH1FontStyles;
    margin: 0 0 18px 0;

    @media (--viewportMedium) {
        margin: 0 0 23px 0;
    }
}

.subHeading {
    margin: 0 0 57px 0;

    @media (--viewportMedium) {
        margin: 0 0 57px 0;
    }
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.link {
    width: 100%;
    margin-top: 25px;

    &:hover {
        text-decoration: none;
    }

    /* First link should not have top margin */
    &:nth-of-type(1) {
        margin-top: 0;
    }
}

.link2Columns {
    @media (--viewportMedium) {
        width: calc(50% - 20px);

        /* First row should not have top margin */
        &:nth-of-type(1),
        &:nth-of-type(2) {
            margin-top: 0;
        }
    }
}

.link3Columns {
    @media (--viewportMedium) {
        width: calc(33.333% - 20px);

        /* First row should not have top margin */
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            margin-top: 0;
        }
    }
}

.imageWrapper {
    position: relative;
    width: 100%;
    border-radius: 4px;
    transition: var(--transitionStyleButton);

    &:hover {
        transform: scale(1.02);
        box-shadow: var(--boxShadowSectionLocationHover);
    }
}

.aspectWrapper {
    padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

    @media (--viewportMedium) {
        padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
    }
}

.image {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
}

.text {
    @apply --marketplaceH2FontStyles;
    color: var(--matterColor);
    margin-top: 15px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 21px;
    }
}
