@import '../../styles/propertySets.css';

.root {
    position: relative;
}

.returnToInbox {
    position: fixed;
    right: 20px;
    z-index: 2;
    padding: 2px 8px;
    background-color: white;
    border-radius: 4px;
    margin-top: 8px;

    @media (--viewportMedium) {
        right: 36px;
    }

    @media (min-width: 1146px) {
        right: calc(((100vw - 1128px)/2) + 36px)
    }

}


.icon {
    width: 20px;
    fill: var(--matterColorNegative);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--viewportLarge) {
        flex-direction: row;
        max-width: 1128px;
        margin: 0 auto 57px auto;
        padding: 0 36px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}

.aDayToAnswerInfo {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 18px 24px 0 24px;
    padding: 5px 0 1px 0;

    @media (--viewportMedium) {
        max-width: 80%;
        margin: 24px 24px 0 24px;
        padding: 1px 0 7px 0;
    }

    @media (--viewportLarge) {
        max-width: 100%;
        margin: 42px 0 0 0;
        padding: 0;
    }
}

.txInfo {
    padding-bottom: 160px;

    @media (--viewportLarge) {
        flex-basis: 538px;
        flex-grow: 0;
        flex-shrink: 1;
        margin-right: 56px;
        margin-bottom: 0;
    }
}

.genericError {
    color: var(--failColor);
    margin: 24px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 32px 24px 0 24px;
    }

    @media (--viewportLarge) {
        margin: 32px 0 0 0;
    }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
    /* Layout */
    display: none;
    width: 100%;
    position: relative;

    @media (--viewportLarge) {
        display: block;
        max-height: 268px;
        overflow-y: hidden;
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    padding-bottom: 66.6667%;
    /* 3:2 Aspect Ratio */
    background-color: var(--matterColorNegative);
    /* Loading BG color */
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    @media (--viewportLarge) {
        border-radius: 2px 2px 0 0;
    }
}

.title,
.typeOfCharge {
    margin: 20px 0px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--marketplaceColor);
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
    bottom: -100%;
    margin: 5px 0px;

    @media (--viewportMedium) {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--marketplaceColor);
        padding: 10px;
        border-radius: 7px;
        margin-bottom: 15px;
        cursor: pointer;
        position: relative;
        bottom: 0px;
    }
}

.wrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.12) 0px 0px 2px;

}

.buttonsWrapper {
    display: flex;

}

.deleteButton {
    color: red;
    cursor: pointer;
    margin-left: 10px;
}

.editButton {
    cursor: pointer;
}

.deleteButton:hover,
.editButton:hover {
    background-color: aliceblue;
}

.modalWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.deleteModal {
    display: block;
    width: 100%;

    @media (--viewportMedium) {
        width: 200px;
    }
}

.cancelModal {
    background-color: rgb(171, 171, 171);
    display: block;
    width: 100%;

    @media (--viewportMedium) {
        width: 200px;
    }

}

.cancelModal:hover {
    background-color: gray;

}

.avatarWrapper {
    /* Position (over the listing image)*/
    margin-left: 24px;
    margin-top: -30px;

    /* Rendering context to the same lavel as listing image */
    position: relative;
    /* Flex would give too much width by default. */
    width: 60px;

    @media (--viewportMedium) {
        margin-top: -32px;
        padding: 2px 0;
    }

    @media (--viewportLarge) {
        margin-left: 48px;
        width: unset;
        padding: 2px 0;
    }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
    position: relative;

    @media (--viewportLarge) {
        display: none;
    }
}

.avatarWrapperMobile {
    composes: avatarWrapper;

    @media (--viewportLarge) {
        display: none;
    }
}

.avatarWrapperDesktop {
    composes: avatarWrapper;
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}

.avatarWrapperProviderDesktop {
    display: none;

    @media (--viewportLarge) {
        display: flex;
        margin-top: 119px;
        gap: 15px;

    }
}

.avatarDesktop {
    @media (--viewportLarge) {
        display: flex;

    }
}

/* PanelHeadings subcomponent */
.headingOrder {
    margin: 29px 24px 0 24px;

    @media (--viewportMedium) {
        max-width: 80%;
        margin: 24px 24px 0 24px;
        padding: 1px 0 7px 0;
    }

    @media (--viewportLarge) {
        max-width: 100%;
        margin: 152px 0 0 0;
        padding: 0;
    }
}

.headingSale {
    margin: 18px 24px 0 24px;
    padding: 5px 0 1px 0;

    @media (--viewportMedium) {
        max-width: 80%;
        margin: 24px 24px 0 24px;
        padding: 1px 0 7px 0;
    }

    @media (--viewportLarge) {
        max-width: 100%;
        margin: 42px 0 0 0;
        padding: 0;
    }
}

.mainTitle {
    display: block;
}

.transactionInfoMessage {
    margin: 18px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 23px 24px 0 24px;
    }

    @media (--viewportLarge) {
        margin: 23px 0 0 0;
    }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
    margin-top: 47px;

    @media (--viewportMedium) {
        margin-top: 40px;
        padding: 4px 0 0px 0;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

/* "aside" section in desktop layout */
.asideDesktop {
    margin: 1px 0 0 0;

    /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

    @media (--viewportLarge) {
        margin: 123px 0 0 0;
    }
}

.detailCard {
    @media (--viewportLarge) {
        /* position: sticky; */
        /* top: -200px; */
        /* This is a hack to showcase how the component would look when the image isn't sticky */
        width: 409px;
        background-color: var(--matterColorLight);
        border: 1px solid var(--matterColorLight);
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);
        border-radius: 2px;
        z-index: 1;
    }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin: 0 48px 33px 48px;
    }
}

.detailCardTitle {
    margin-bottom: 10px;

    @media (--viewportLarge) {
        margin-top: 14px;
        margin-bottom: 0;
    }
}

.detailCardSubtitle {
    @apply --marketplaceH5FontStyles;

    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportLarge) {
        margin-top: 9px;
        margin-bottom: 0;
    }
}

/* AddressLinkMaybe subcomponent */
.address {
    @apply --marketplaceH5FontStyles;
    margin: 1rem 0 0 0;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    background-color: var(--marketplaceColor);
    box-shadow: var(--boxShadow);
}

.addressTitle {
    display: block;
    font-size: 10px;
}

.addressLink {
    color: white;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
    @apply --marketplaceH5FontStyles;
    color: white;
    margin: 0 24px 24px 24px;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: var(--boxShadow);
    background-color: var(--marketplaceColor);
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
    /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin: 0 24px 0 24px;

    @media (--viewportLarge) {
        margin: 37px 48px 26px 48px;
        margin: 32px 48px 24px 48px;
        padding: 4px 0 4px 0;
    }
}

.breakdown {
    margin: 14px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 18px 24px 0 24px;
    }

    @media (--viewportLarge) {
        margin: 24px 48px 47px 48px;
        padding: 6px 0 2px 0;
    }
}

.breakdownContainer {
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}

/* FeedSection subcomponent */
.feedHeading {
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
        margin: 0;
    }
}

.feed {
    margin-top: 20px;
}

.messageError {
    color: var(--failColor);
    margin: 13px 0 22px 0;

    @media (--viewportMedium) {
        margin: 13px 0 23px 0;
    }

    @media (--viewportLarge) {
        margin: 12px 0 23px 0;
    }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
    margin: 46px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 40px 24px 0 24px;
    }

    @media (--viewportLarge) {
        margin: 43px 0 0 0;
    }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
    position: relative;
    margin: 46px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 49px 24px 0 24px;
        border-top: 0;
    }

    @media (--viewportLarge) {
        margin: 47px 0 0 0;
    }
}

.sendingMessageNotAllowed {
    color: var(--matterColorAnti);

    position: relative;
    margin: 46px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 49px 24px 0 24px;
        border-top: 0;
    }

    @media (--viewportLarge) {
        margin: 47px 0 0 0;
    }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
    /* Position action button row above the footer */
    z-index: 9;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 18px 24px 18px 24px;

    /* Contain repainting to this component only */
    /* 3D painting container helps scrolling */
    transform: translate3d(0, 0, 0);

    box-shadow: var(--boxShadowTop);
    background-color: white;

    @media (--viewportLarge) {
        z-index: unset;
        position: static;
        box-shadow: none;
        width: auto;
        margin: 0 48px 0 48px;
        padding: 0;
    }
}

.actionButtonWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (--viewportLarge) {
        flex-direction: column;
    }

    & button:first-child {
        margin: 0 12px 0 0;

        @media (--viewportLarge) {
            margin: 8px 0 0 0;

            /* Switch order in desktop layout with accept button being on the top */
            order: 1;
        }
    }
}

.actionError {
    @apply --marketplaceH5FontStyles;
    color: var(--failColor);
    margin: 0 0 11px 0;

    @media (--viewportMedium) {
        margin: 0 0 10px 0;
    }

    @media (--viewportLarge) {
        margin: 0;
    }
}

.actionErrors {
    width: 100%;
    text-align: center;

    @media (--viewportLarge) {
        position: absolute;
        top: 151px;
    }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
    display: block;

    @media (--viewportLarge) {
        display: none;
    }
}

.desktopActionButtons {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin-bottom: 48px;
    }
}

/* BookingPanel subcompnent */
.bookingPanel {
    /* margin: 16px 48px 48px 48px; */

    &>div:first-child {
        box-shadow: none !important;
        padding: auto;

        @media (--viewportMedium) {
            padding: 20px 20px 0 20px;
        }
    }
}

.bookingTitle {
    /* Font */
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 1px;
}

.cancelBtn {
    background-color: var(--failColor);
    color: white;

    &:hover {
        background-color: var(--failColorLight);
        color: white !important;
    }

    &:focus {
        border: 1px solid var(--failColor);
        color: var(--failColor);
    }
}

.individualOffer {
    padding: 0 24px;

    /* margin-bottom: 35px; */
    @media (--viewportMedium) {
        padding: 0;
        width: 410px;
        margin-bottom: 0;
    }
}



.individualOfferText {
    padding: 0 24px;

    /* margin-bottom: 35px; */
    @media (--viewportMedium) {
        padding: 0 24px;
        /* width:410px; */
        margin-bottom: 0;
    }

    @media (--viewportLarge) {
        padding: 0;
        width: 410px;
        margin-bottom: 0;
    }
}

.ifYourGuest {

    text-align: center;
    color: #808080;
    font-size: 14px;
}

.sendMessage {
    margin: 0px;

}

.plansWrapper {
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #DCEDE0;
}

.EnquiryText {
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    color: #013333;
    font-size: 20px;
}

.paddingWrapper {
    padding: 0 24px;

    @media (--viewportMedium) {
        padding: 0px 24px;
        margin-bottom: 15px;

    }

    @media (--viewportLarge) {
        padding: 0px;
        margin-bottom: 15px;
    }
}

.columnsWrapper {
    display: flex;
    justify-content: space-between;
}

.leftColumn {
    font-weight: 600;
    text-align: left;
    width: 45%;
}

.rightColumn {
    font-weight: 600;
    text-align: right;
    width: 45%;
}

.removeFeeCheckbox {
    margin: 20px 0px;
}

.checkboxLabel {
    font-size: 14px;
    font-weight: 400;
}

.customBreakDownProvider {
    /* margin: -120px 20px 20px 20px; */
    margin: 20px;
    padding-bottom: 160px;

    @media (--viewportMedium) {
        margin: 20px;
        padding-bottom: unset;
    }
}

.customBreakDownCustomer {
    margin: 20px;
}

.mobileOnly {
    margin: 20px 0 0 20px;

    @media (--viewportLarge) {
        display: none;
    }
}

.desktopOnly {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin-left: 40px;
    }
}

.customBadge {
    display: inline-flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid;

    font-size: 12px;
    font-weight: 500;
    line-height: 24px;

    @media (--viewportMedium) {
        font-size: 14px;
        padding: 5px 10px;
    }
}
.customBadgeDefault {
    color: #8E50AB;
    background-color: rgba(142, 80, 171, 0.10);
}
.customBadgeInquiry {
    color: #FA0;
    background-color: rgba(255, 170, 0, 0.10);;
}
.customBadgeCancelled {
    color: #D10000;
    background-color: rgba(209, 0, 0, 0.10);
}

.cancellationPolicy {
    margin: 24px auto;
    width: 100%;
}

.cancellationPolicyMobile {
    margin: 40px 20px 20px 20px;
    width: calc(100% - 40px);

    @media (--viewportLarge) {
        display: none;
    }
}

.noOfGuest {
    margin: 20px 0 0 20px;
    font-size: 16px;
}
