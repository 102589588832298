.root {
}

.input {
    border-bottom-color: var(--attentionColor);

    &:disabled {
        border-bottom-color: var(--matterColorNegative);
        color: var(--matterColorAnti);
        cursor: default;

        &:placeholder {
            color: var(--matterColorAnti);
        }
    }
}

.inputSuccess {
    border-bottom-color: var(--successColor);
}

.inputError {
    border-bottom-color: var(--failColor);
}

.textarea {
}

.infoText {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    text-align: right;
}
