@import '../../styles/propertySets.css';

.root {
    padding-top: 24px;
    padding-bottom: 17px;
    border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
    @apply --marketplaceH3FontStyles;

    /* Baseline adjustment for label text */
    margin-top: 0;
    margin-bottom: 12px;
    padding: 4px 0 2px 0;
}

.filterLabel {
    color: var(--matterColorDark);
}

.filterLabelSelected {
    color: var(--marketplaceColor);
}

.labelButton {
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
    padding: 0;
    cursor: pointer;
}

.optionsContainerOpen {
    height: auto;
    padding-bottom: 30px;
}

.optionsContainerClosed {
    height: 0;
    overflow: hidden;
}

.optionBorder,
.optionBorderSelected {
    position: absolute;
    height: calc(100% - 12px);
    top: 4px;
    left: -24px;
    transition: width var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBorder {
    width: 0;
    background-color: var(--matterColorDark);
}

/* left static border for selected element */
.optionBorderSelected {
    width: 8px;
    background-color: var(--matterColorDark);
}

.option {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    color: var(--matterColor);

    /* Layout */
    display: block;
    position: relative;
    margin: 0;
    padding: 4px 0 8px 20px;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--matterColorDark);
    }

    &:hover .menuItemBorder {
        width: 6px;
    }

    &:disabled {
        color: var(--matterColorAnti);
        cursor: default;
    }
}

.optionSelected {
    composes: option;
    color: var(--matterColorDark);
}
