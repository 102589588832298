@import '../../styles/propertySets.css';

.root {
}

.newPasswordSection {
    margin-top: 16px;
    margin-bottom: 46px;
    padding-top: 6px;

    @media (--viewportMedium) {
        margin-bottom: 56px;
        padding-top: 0;
    }
}

.confirmChangesSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 0;
    opacity: 0;
}

.confirmChangesSectionVisible {
    opacity: 1;
}

.confirmChangesTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.confirmChangesInfo {
    margin-top: 0;
    margin-bottom: 37px;

    @media (--viewportMedium) {
        margin-bottom: 48px;
    }
}

.bottomWrapper {
    margin-top: 46px;

    @media (--viewportMedium) {
        margin-top: 96px;
    }
}

.error {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 24px 0;

    @media (--viewportMedium) {
        margin: 24px 0;
    }
}

.helperLink {
    @apply --marketplaceLinkStyles;
    color: var(--matterColor);
    font-weight: var(--fontWeightMedium);
    text-decoration: underline;
}

.emailStyle {
    font-weight: var(--fontWeightBold);
    word-wrap: break-word;
}
