:root {
    /**
 * This file contains only Custom Sets of Properties (@apply-rule).
 * This was originally part of cssnext package and was an interesting
 * concept, because property sets would not suffer from specificity
 * problems that arise from creating a single CSS file on build step.
 *
 * However, W3C has abandoned this concept before it became an
 * official standard. It would be a good idea to reduce the usage.
 * http://tabatkins.github.io/specs/css-apply-rule/
 *
 * Property sets defined in this file are imported and injected to
 * correct places during PostCSS build step.
 *
 * Note: if you add plain CSS styles to this file,
 * you'll end up seeing it repeated in development mode on every
 * component that @imports this file.
 */

    /* ================ CSS Custom Property Sets ================ */

    /* Full screen Background image located in root-folder/src/assets */
    --backgroundImage: {
        /* Gradient direction and overlaying the black color on top of the image for better readability */
        background: linear-gradient(
                -45deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.6)
            ),
            url('../../assets/background-1440.jpg');

        /* Add loading color for the div */
        background-color: var(--matterColor);

        /* Cover the whole screen with the background image */
        background-size: cover;

        /* Align the image within the container */
        background-position: center center;
    }

     /* Fixed Background image position ---- applied under class: .layoutWrapperMain */
     --FixedBackgroundImage: {
        background-color: var(--matterColor);
        background-size: auto 100%;
        background-position: calc(100vh - 150vh) 0;
        background-repeat: no-repeat;
        background-attachment: fixed;

        @media only screen and (min-width: 2560px){
            background-position: 0vh;
        }
    }

    /* Page layout design 40 image 60 content ---- applied under class: .root */
    --PageLayout40Img60Content: {
        align-self: flex-end;
        width: 60%;
        background: var(--matterColorLight);

        @media only screen and (max-width: 600px){
            width: 100%!important;
        }
    }

    /* Page layout design > 60 content part  ---- applied under class: .content */
    --PageLayout60ContentPart: {
        margin-right: auto;
        margin-top: 0%;
        margin-left: 5%;
        border-bottom: 0px;
    
        @media only screen and (max-width: 600px){
            margin-left: 0%;
        }
    }

    /**
   * Usage example: .root { @apply --marketplaceDefaultFontStyles; }
   */
    --marketplaceDefaultFontStyles: {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        /* No margins for default font */

        @media (--viewportMedium) {
            font-size: 16px;
            line-height: 32px;
        }
    }

    --marketplaceSmallFontStyles: {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 24px;
    }

    /* ================ Body font ================ */

    --marketplaceBodyFontStyles: {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 12px;
        margin-bottom: 12px;

        @media (--viewportMedium) {
            font-size: 16px;
            line-height: 32px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    /* ================ Hero title ================ */

    --marketplaceHeroTitleFontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.7px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 25px;
        margin-bottom: 14px;

        @media (--viewportMedium) {
            font-size: 64px;
            line-height: 64px;
            letter-spacing: -1.5px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 25px;
            margin-bottom: 23px;
        }
    }

    /* ================ Topbar ================ */

    /* Compose text label items on top of this */
    /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
    --TopbarDesktop_label: {
        display: inline-block;
        margin: 28px 0;
        text-decoration: inherit;

        @media (--viewportMedium) {
            margin: 28px 0;
        }
    }

    --TopbarDesktop_linkHover: {
        border-bottom: 0px solid;
        transition: var(--transitionStyleButton);

        &:hover {
            border-bottom: 4px solid var(--marketplaceColor);
            border-radius: 0;
            text-decoration: none;
        }
    }

    /* ================ Modal title ================ */

    --marketplaceModalTitleStyles {
        font-weight: var(--fontWeightSemiBold);
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.5px;
        margin: 0;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightSemiBold);
            line-height: 40px;
            margin: 0;
        }
    }

    /* ================ H1, H2, H3, H4, H5 & H6 ================ */

    --marketplaceH1FontStyles: {
        font-weight: var(--fontWeightSemiBold);
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.5px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 18px;
        margin-bottom: 18px;

        @media (--viewportMedium) {
            font-size: 40px;
            font-weight: var(--fontWeightSemiBold);
            line-height: 48px;
            letter-spacing: -1px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    --marketplaceH2FontStyles: {
        font-weight: var(--fontWeightSemiBold);
        font-size: 21px;
        line-height: 24px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 21px;
        margin-bottom: 17px;

        @media (--viewportMedium) {
            line-height: 32px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 21px;
            margin-bottom: 19px;
        }
    }

    --marketplaceH3FontStyles: {
        font-weight: var(--fontWeightSemiBold);
        font-size: 18px;
        line-height: 24px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 16px;
        margin-bottom: 14px;

        @media (--viewportMedium) {
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    --marketplaceH4FontStyles: {
        font-weight: var(--fontWeightMedium);
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 12px;
        margin-bottom: 12px;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightMedium);
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }

    --marketplaceH5FontStyles: {
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 10px;
        margin-bottom: 8px;

        @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10px;
            margin-bottom: 14px;
        }
    }

    --marketplaceH6FontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 10px;
        margin-bottom: 8px;

        @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10px;
            margin-bottom: 6px;
        }
    }

    /* ================ Other fonts ================ */

    --marketplaceTinyFontStyles: {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightRegular);
        font-size: 13px;
        line-height: 18px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 9.5px;
        margin-bottom: 8.5px;
        -webkit-font-smoothing: subpixel-antialiased;

        @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10.5px;
            margin-bottom: 13.5px;
        }
    }

    --marketplaceMessageFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightRegular);
        font-size: 16px;
        line-height: 24px;
    }

    --marketplaceMessageDateFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightRegular);
        font-size: 12px;
        line-height: 18px;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightMedium);
            font-size: 12px;
            line-height: 24px;
        }
    }

    --marketplaceTxTransitionFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 18px;

        @media (--viewportMedium) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    --marketplaceSearchFilterLabelFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightSemiBold);
        font-size: 13px;
        line-height: 18px;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightMedium);
            font-size: 13px;
            line-height: 20px;
        }
    }

    --marketplaceSearchFilterSublabelFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 18px;
    }

    --marketplaceListingAttributeFontStyles {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 24px;
    }

    /* ================ Tabbed navigation font styles ================ */

    --marketplaceTabNavFontStyles {
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;

        @media (--viewportLarge) {
            font-weight: var(--fontWeightSemiBold);
            font-size: 20px;
            line-height: 24px;
        }
    }

    --marketplaceTabNavHorizontalFontStyles {
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
    }

    /* ================ Clearfix solution ================ */

    --clearfix {
        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }

    /* ================ Links ================ */

    --marketplaceLinkStyles: {
        /* Position and dimensions */
        display: inline;
        margin: 0;
        padding: 0;

        /* Borders */
        border: none;

        /* Colors */
        color: var(--marketplaceColor);

        /* Text size should be inherited */
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    /* ================ Buttons ================ */

    --marketplaceButtonFontStyles: {
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;

        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;

        @media (--viewportMedium) {
            /* TODO: Make sure button text aligns with the baseline */
        }
    }
    --marketplaceButtonStyles {
        @apply --marketplaceButtonFontStyles;

        /* Dimensions */
        display: block;
        width: 100%;
        min-height: 59px;
        margin: 0;

        /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
        padding: 17px 0 17px 0;

        @media (--viewportMedium) {
            padding: 20px 0 20px 0;
        }

        /* Borders */
        border: none;
        border-radius: var(--borderRadius);

        /* Colors */
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);

        /* Text */
        text-align: center;
        text-decoration: none;

        /* Effects */
        transition: all var(--transitionStyleButton);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            background-color: var(--marketplaceColorDark);
            text-decoration: none;
            box-shadow: var(--boxShadowButton);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            cursor: not-allowed;
            box-shadow: none;
        }

        @media (--viewportMedium) {
            min-height: 65px;
        }
    }
    --marketplaceButtonStylesPrimary {
        @apply --marketplaceButtonStyles;

        background-color: var(--successColor);
        color: var(--matterColorLight);

        &:hover,
        &:focus {
            background-color: var(--successColorDark);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
        }
    }
    --marketplaceButtonStylesSecondary {
        @apply --marketplaceButtonStyles;

        background-color: var(--matterColorLight);
        color: var(--matterColor);

        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
        padding: 18px 0 0 0;

        border-width: 1px;
        border-style: solid;
        border-color: var(--matterColorNegative);

        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            border-color: var(--matterColorAnti);
        }
        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorLight);
            border-color: var(--matterColorNegative);
        }
    }

    /* ================ Inputs ================ */

    --marketplaceInputStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0 5px 0;

        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--marketplaceColor);
        border-radius: 0;

        &::placeholder {
            color: var(--matterColorAnti);
        }

        /* Effects */

        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }

        @media (--viewportMedium) {
            padding: 4px 0 2px 0;
        }
    }

    --marketplaceInputFontStyles: {
        font-family: var(--fontFamily);
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        /* No margins for default font */

        @media (--viewportMedium) {
            font-size: 16px;
            line-height: 32px;
        }
    }

    --marketplaceSelectStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0 5px 0;

        /* Unset user agent styles */
        appearance: none;

        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--marketplaceColor);
        border-radius: 0;

        /* Background */
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
        background-size: 16px 16px;
        background-position: center right;

        &:disabled {
            background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%23E7E7E7" fill="%23E7E7E7" fill-rule="evenodd"/></svg>');
        }

        /* Effects */

        cursor: pointer;
        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }

        @media (--viewportMedium) {
            padding: 4px 0 2px 0;
        }
    }

    /* ================ Modals ================ */

    --marketplaceModalRootStyles {
        /* Page is using flex: AuthenticationPage's .root takes all available space */
        flex-grow: 1;

        /* AuthenticationPage's root uses flexbox */
        display: flex;

        @media (--viewportMedium) {
            justify-content: center;
            align-items: flex-start;
        }
    }

    --marketplaceModalFormRootStyles {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        height: 100%;
    }

    --marketplaceModalBaseStyles {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        padding: var(--modalPadding);

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);
        border-bottom: none;

        @media (--viewportMedium) {
            flex-basis: 480px;
            flex-grow: 0;
            /* min-height: 568px; */
            padding: var(--modalPaddingMedium);
            margin-top: 12.5vh;
            margin-bottom: 12.5vh;
            border-bottom: 8px solid var(--marketplaceColor);
        }
    }

    --marketplaceModalInMobileBaseStyles {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0;

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);

        @media (--viewportMedium) {
            flex-basis: 480px;
            flex-grow: 0;
            height: 100%;
            padding: 0;
            background-color: transparent;
            border-radius: 0;
        }
    }

    /* Modal title */

    --marketplaceModalTitleStyles {
        font-weight: var(--fontWeightSemiBold);
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.5px;
        margin: 0;
        font-weight: var(--fontWeightBold);

        @media (--viewportMedium) {
            font-weight: var(--fontWeightSemiBold);
            line-height: 40px;
            letter-spacing: -0.9px;
            margin: 0;
        }
    }

    /* Modal message */

    --marketplaceModalParagraphStyles {
        margin: 18px 0 0 0;

        @media (--viewportMedium) {
            margin: 24px 0 0 0;
        }
    }

    /* Bottom wrapper for the modal */

    --marketplaceModalBottomWrapper {
        text-align: center;
        margin-top: 60px;
        align-self: stretch;

        @media (--viewportMedium) {
            margin-top: 96px;
        }
    }

    /* Adjust the bottomWrapper's margin to match modals without the helper text */
    --marketplaceModalBottomWrapperText {
        margin-top: -24px;
        padding-bottom: 8px;
        text-align: start;

        @media (--viewportMedium) {
            padding-bottom: 0;
        }
    }

    --marketplaceModalHelperText {
        @apply --marketplaceTinyFontStyles;
        color: var(--matterColorAnti);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    --marketplaceModalHelperLink {
        @apply --marketplaceTinyFontStyles;
        color: var(--matterColor);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    /* Modal Close icon */

    --marketplaceModalCloseStyles {
        /* Position inside modal */
        position: fixed;
        top: 0;
        right: 0;

        /* Some content (like map) is positioning itself on top of close button without z-index handling */
        z-index: calc(var(--zIndexModal) + 1);

        /* Safari didn't know how to baseline-align svg icon and text */
        display: flex;
        align-items: flex-start;
        width: auto;

        /* Sizing (touch area) */
        padding: 24px;
        margin: 0;
        border: 0;

        @media (--viewportMedium) {
            padding: 27px 30px;
            position: absolute;
        }

        /* Colors */
        background-color: transparent;
        color: var(--matterColor);

        cursor: pointer;

        &:enabled:hover {
            background-color: transparent;
            color: var(--matterColorDark);
            box-shadow: none;
            text-decoration: none;
        }
        &:enabled:active {
            background-color: transparent;
            color: var(--matterColorDark);
        }
        &:disabled {
            background-color: transparent;
        }
    }

    --marketplaceModalIconStyles {
        height: 48px;
        margin-bottom: 12px;

        @media (--viewportMedium) {
            height: 64px;
            margin-bottom: 23px;
        }
    }

    --marketplaceModalCloseText {
        /* Font */
        @apply --marketplaceH6FontStyles;
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    --marketplaceModalCloseIcon {
        display: inline-block;
        margin-left: 8px;

        /* Move X icon vertically to align it with the close text. */
        margin-top: 3px;

        box-sizing: content-box;

        @media (--viewportMedium) {
            margin-top: 2px;
        }
    }

    --marketplaceModalErrorStyles {
        @apply --marketplaceH4FontStyles;
        margin-top: 24px;
        color: var(--failColor);
    }

    --marketplaceModalPasswordMargins {
        /* Leave space between the input and the button below when the
    viewport height is small */
        margin-top: 24px;

        @media (--viewportMedium) {
            margin-top: 32px;
        }
    }
}
